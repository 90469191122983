export default function reservebarPopup() {
	document
		.querySelectorAll('.reservebar-popup-trigger')
		.forEach(function (trigger) {
			if (
				trigger.classList.contains(
					'reservebar-popup-trigger--click-assigned'
				)
			) {
				return;
			}
			trigger.addEventListener('click', function (event) {
				event.preventDefault(); 
				let liquidGroupingID =
					trigger.attributes['reservebar-liquid-id']?.value;
				generatePopup(liquidGroupingID);
				liquidSDK.init({
					clientId: 'cc615190c9d297b935482113af23d8b1',
					includeMerchandise: false
				});
			});
			trigger.classList.add('reservebar-popup-trigger--click-assigned');
		});

		document.querySelector('.main-container').addEventListener('click', function(event) {
			if (event.target.classList.contains('quiz-add-to-cart')) {
				event.preventDefault();
				let liquidGroupingID = event.target.closest('.reservebar-popup-trigger').getAttribute('reservebar-liquid-id');
				generatePopup(liquidGroupingID);
				liquidSDK.init({
					clientId: 'cc615190c9d297b935482113af23d8b1',
					includeMerchandise: false
				});
			}
			/*if (event.target.parentElement.classList.contains('reservebar-popup-trigger')) {
				event.preventDefault();
				let liquidGroupingID = event.target.getAttribute('reservebar-liquid-id');
				generatePopup(liquidGroupingID);
				liquidSDK.init({
					clientId: 'cc615190c9d297b935482113af23d8b1',
					includeMerchandise: false
				});
			}*/
		});

	import(
		'Assets/scss/modules/library-modules/reservebar-popup/reservebar-popup.scss'
	);
}
if(document.querySelector('.main-container')){
document.querySelector('.main-container').addEventListener('click', function(event) {
	if (event.target.closest('.reservebar-popup-trigger') && event.target.classList.contains('post-type-filter-grid-v2__cta-span')  ) {
		event.preventDefault();
		let liquidGroupingID = event.target.closest('.reservebar-popup-trigger').getAttribute('reservebar-liquid-id');
		
		if (generatePopup(liquidGroupingID)) {
			liquidSDK.init({
				clientId: 'cc615190c9d297b935482113af23d8b1',
				includeMerchandise: false
			});
		}
	}
});
}
if(document.querySelector('body.page')){
	document.querySelector('body.page').addEventListener('click', function(event) {
		if (event.target.classList.contains('liquid-cart-atc')) {
			var group_id = document.querySelector('.reservebar-popup__content[liquid-id]').getAttribute('liquid-id');
			var product_name =  document.querySelector('a.reservebar-popup-trigger[reservebar-liquid-id="'+group_id+'"]').closest('.post-type-filter-grid-v2__post-container').querySelector('.post-type-filter-grid-v2__post-title h3').innerHTML;
			var product_price = document.querySelector('.liquid-fulfillment-method-selector .selected .liquid-price').innerHTML;
			var product_qty = document.querySelector('.reservebar-popup__content .liquid-cart-qty-selector').value;
			console.log( product_name + " " +  product_price + ' ' + product_qty);
		}
	});
}

function generatePopup(liquidGroupingID) {
	if (document.querySelectorAll('.reservebar-popup__container').length > 0) {
        return false;
    }
	const popup = document.createElement('div');
	popup.innerHTML = `
        <div class="reservebar-popup reservebar-popup__container" style="" >
            <div class="reservebar-popup__content">
                <div class="reservebar-popup__close"></div>
				<p class='sub_heading p-sizing'>Buy Now</p>
                <h4 class="reservebar-popup__title">Check Availability</h4>
                <div class="reservebar-popup__content" liquid-id=${liquidGroupingID}>
				
                <div liquid-address-typeahead></div>

				<div class='liquid-cmsg'>
				<p class="p-xxs-sizing">All orders are fulfilled by ReserveBar, an independent third party that facilitates orders with licensed retailers in your state.</p>    
				</div>

				<div liquid-fulfillment-method-selector></div>
				<div class="liquidError_34iJe" liquid-error=""></div>
				<div class="rb-add-to-cart-ctas">
					<div liquid-qty-selector><select disabled class="liquid-cart-qty-selector"><option value="1">1</option></select></div>
					<div liquid-atc><button disabled class="liquid-cart-atc">Add to Cart</button></div>
				</div>
                </div>
            </div>
        </div>`;
	document.body.appendChild(popup);
	document.body.classList.add('lock-position');
	const reservebarClose = document.querySelector('.reservebar-popup__close');
	reservebarClose.addEventListener('click', function () {
		popup.remove();
		document.body.classList.remove('lock-position');
	});
	return true;
}
