import {fadeIn} from '@pernod-ricard-global-cms/jsanimations';

export default function siteheaderJs(options = {}) {
	try {
		// CSS for site-header should usually be included in critical.scss
		const {block} = options;
		fadeIn(block);

		const documentBody = document.body;
		const navContainer = document.querySelector('.header-nav');
		var hashLinkID = '';
		const hamburgerWrapper = document.querySelector(
			'.header-interactions__hamburger-wrapper'
		);
		const hamburger = document.querySelector(
			'.header-interactions__hamburger'
		);
		const hashLink = document.querySelectorAll('a');
		const hamburgerBars = hamburger.querySelectorAll(
			'.header-interactions__hamburger-bar'
		);
		const itemsWithChildren = navContainer.querySelectorAll(
			'a.menu-item-has-children'
		);

		const closeSubnavs = () => {
			navContainer
				.querySelectorAll('.header-nav__item.active')
				.forEach((el) => el.classList.remove('active'));
			documentBody.classList.remove('mobile-menu-active');
		};

		hamburgerWrapper.addEventListener('click', function () {
			if (screen.width < 1024) {
				hamburger.classList.toggle('active');
				hamburgerBars.forEach((item) => {
					item.classList.toggle('active');
				});
				documentBody.classList.toggle('mobile-menu-active');
			}
		});

		itemsWithChildren.forEach((item) => {
			item.addEventListener('click', function (e) {
				e.preventDefault();
				item.parentNode.classList.toggle('active');
			});
		});

		hashLink.forEach((link) => {
			link.addEventListener('click', function (event) {
				if (link.href.indexOf('#') !== -1) {
					event.preventDefault();
					if (link.hash) {
						const targetElement = document.querySelector(link.hash);
						const targetOffsetTop = targetElement.offsetTop;
						hashLinkID = link.hash;
						window.scrollTo({
							top: targetOffsetTop - 100,
							behavior: 'smooth'
						});
					}
				}
			});
		});

		const detectScrolling = () => {
			if (window.scrollY > 10 && !block.classList.contains('scrolling')) {
				block.classList.add('scrolling');
			} else if (
				window.scrollY < 10 &&
				block.classList.contains('scrolling')
			) {
				block.classList.remove('scrolling');
			}

			if (hashLinkID) {
				const targetElement = document.querySelector(hashLinkID);
				const targetOffsetTop = targetElement.offsetTop;

				if (window.scrollY > targetOffsetTop) {
					hashLinkID = '';
					window.scrollTo({
						top: targetOffsetTop - 100
					});
				}
			}
		};

		window.addEventListener('scroll', detectScrolling, false);
		window.addEventListener('resize', closeSubnavs, true);

		// Function to handle the addition of the loaded class
		function handleLoadedClass(mutationsList, observer) {
			mutationsList.forEach(function (mutation) {
				if (
					mutation.type === 'attributes' &&
					mutation.attributeName === 'class' &&
					mutation.target.classList.contains('loaded')
				) {
					// Call the function to add the span tag after the img tag
					//addBottleShadow(mutation.target);
				}
			});
		}

		// Function to add the bottle shadow
		function addBottleShadow(element) {
			// Get all img tags within the current element
			var imgTags = element.getElementsByTagName('img');

			// Loop through each img tag
			Array.from(imgTags).forEach(function (imgTag) {
				// Check if a bottle_shadow span tag already exists after the img tag
				if (
					!imgTag.nextElementSibling.classList.contains(
						'bottle_shadow'
					)
				) {
					// Create a new span element
					var span = document.createElement('span');
					// Add the "bottle_shadow" class to the span element
					span.classList.add('bottle_shadow');

					// Calculate height of bottle_shadow based on image width
					var imgWidth = imgTag.width;
					var shadowHeight = (imgWidth * 67) / 300;

					// Set height and margin-top for bottle_shadow
					span.style.width = imgWidth + 'px';
					span.style.height = shadowHeight + 'px';
					span.style.marginTop = (shadowHeight / 2 + 15) * 1 + 'px';
					//span.style.marginTop = shadowHeight + 'px';

					// Insert the new span element after the current img tag
					imgTag.parentNode.insertBefore(span, imgTag.nextSibling);
				}
			});
		}

		// Create a MutationObserver instance
		var observer = new MutationObserver(handleLoadedClass);

		// Start observing the DOM for changes
		observer.observe(document.body, {
			attributes: true,
			subtree: true,
			attributeFilter: ['class']
		});

		var parentGroup = document.querySelector('.bv_stars_button_container');

		if (parentGroup) {
			// Get all path elements within the parent container
			var paths = parentGroup.querySelectorAll('path');

			// Set the fill attribute for each path element
			paths.forEach(function (path) {
				path.removeAttribute('style');
			});
		}

		/* 
		window.onbeforeunload = function() {
			localStorage.setItem('isshow', null);
		};
		*/
		const popupForm = document.querySelector('.popup_form');
		if (popupForm) {
			document
				.querySelector('a[href="#popup_form"]')
				.addEventListener('click', function (e) {
					e.preventDefault();
					document
						.querySelector('.popup_form')
						.insertAdjacentHTML(
							'afterbegin',
							'<a href="#" class="close_popup"></a>'
						);
					document
						.querySelector('.popup_form')
						.classList.remove('hide');
					document.querySelector('body').classList.add('overflow-hidden');
				});
		
				/*
				var isshow = localStorage.getItem('isshow');
				if (isshow== null) {
					localStorage.setItem('isshow', 1);
					// Show popup here
					document
						.querySelector('.popup_form')
						.insertAdjacentHTML(
							'afterbegin',
							'<a href="#" class="close_popup"></a>'
						);
					document
						.querySelector('.popup_form')
						.classList.remove('hide');
					document.querySelector('body').classList.add('overflow-hidden');
				}
				*/
		}

		document.body.addEventListener('click', function (e) {
			if (e.target.classList.contains('close_popup')) {
				e.preventDefault();
				document.querySelector('.popup_form .close_popup').remove();
				document.querySelector('.popup_form').classList.add('hide');
				document.querySelector('body').classList.remove('overflow-hidden');
			}
		});

		var images = document.querySelectorAll('img');

		// Loop through each image
		images.forEach(function (img) {
			// Add event listener for mouseenter
			img.addEventListener('mouseenter', function (event) {
				// Prevent default behavior
				event.preventDefault();
				// Check if the image has a parent with class 'image_animation'
				if (img.closest('.image_animation')) {
					// Add class 'animation' to the image
					img.classList.add('animation');
				}
			});
		});
		
		window.addEventListener('resize', checkWindowResize);
		window.addEventListener('scroll', reset_slider_arrow_position);
		
		function reset_slider_arrow_position(){
			if( window.innerWidth >= 744 ){
				return;
			}
			var bourbonRecipes = document.querySelectorAll('.bourbon_cocktail_recipes');
			bourbonRecipes.forEach(function(recipe){
				if (recipe.querySelector('.swiper-slide-active')) {
					var slideWidth = recipe.querySelector('.swiper-slide').offsetWidth;
					var imgHeight = recipe.querySelector('.swiper-slide-active img').offsetHeight;
					var documentWidth = document.documentElement.clientWidth;
					var nextButton = recipe.querySelector('.carousel-multi-layout-v2 .swiper-button-next');
					nextButton.style.right = documentWidth - slideWidth - 20 - 35 + 'px';
					nextButton.style.top = (imgHeight / 2) - 20 + 'px';       
				} 
			});
		}
		
		function checkWindowResize() {
			clearTimeout(checkWindowResize.timeout);

			checkWindowResize.timeout = setTimeout(function() {
				reset_slider_arrow_position();
			}, 500); 
		}
		


	} catch (error) {
		console.error(error);
	}
}
